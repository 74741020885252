/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-trailing-spaces */

import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../assets/Icons/add.svg'
import styled from 'styled-components'
import MinusIcon from '../assets/Icons/minus.svg'
import '../screens/Css/BuyTicketModal.css'
import {
    BusinesService,
    CommonUtility,
    NotificationStatus,
    NotificationText,
} from 'utility'
import { GetBusinessCategoryList, useQueryString } from 'hooks'
import {
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    PushNotification,
} from 'components'
import { BrowserUtility } from 'utility/browser-utility'
import { Checkbox, Form, Tabs } from 'antd'
import { DangerButton, FormTextFormField } from 'elements'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'context'
import { PopUpModalContainer } from 'components/EventForms/PopUpModalContainer'

const UserSchema = yup.object().shape({
    CellPhone: yup.string(),
    Email: yup.string().email(),
    SendWhatsAPPTicket: yup.boolean(),
})

const TicketMain = styled.div`
    width: 95%;
    margin: 0 auto 0px;
    .header-bus {
        font-size: 18px;
        position: sticky;
        width: 100%;
        margin-top: 10px;
    }
    .heading-main {
        display: flex;
        margin: 0px auto 20px;
        width: 77%;
        justify-content: space-between;
        align-items: center;
    }
    .ant-tabs-content {
        margin-bottom: 21vh;
        height: 52vh;
        overflow-y: auto;
    }
    .heading {
        font-weight: 700;
        font-size: 22px;
        padding-bottom: 10px;
    }
    @media (min-width: 200px) and (max-width: 480px) {
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
            .heading {
                font-size: 20px;
            }
        }
    }
    @media (min-width: 481px) and (max-width: 768px) {
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
        }
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        padding-top: 25px;
        .heading-main {
            margin: 0px 45px;
            margin-bottom: 10px;
        }
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
        }
    }
`
const ModalContainer = styled.div`
    overflow-x: visible;
    width: 90%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 5px;
    // background: rgba(255, 255, 255, 0.1);
    .ant-tabs {
        padding-inline: 0px;
    }
    .buy-ticket-section {
        border-radius: 20px;
        margin: 0 auto;
        width: 100%;
    }
    @media (min-width: 200px) and (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        width: 90%;
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        width: 100%;
    }
`
const ModalWrapper = styled.div`
    background: #242631;
    border-radius: 14px;
    padding: 10px 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item-name {
        position: relative;
    }
    .img {
        width: 50px;
        border-radius: 5px;
    }
    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 0;
        right: 0;
    }
    .item-name:hover .tooltip-text {
        visibility: visible;
    }
`
const OuterBox = styled.div`
    display: flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    align-items: center;
    gap: 12px;
`
const Icon = styled.div`
    width: 25px;
    height: 25px;
    background: rgba(138, 138, 138, 0.3);
    border-radius: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
`
const NoEvent = styled.div`
    margin: 100px auto;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
`
const SoldTicket = styled.div`
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-width: 110px;
    span {
        color: #ff384e;
    }
`
const AddItemBtn = styled.div`
    padding: 6px 12px;
    background: ${({ theme }) => theme.colors.danger};
    height: 35px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    min-width: 90px;
`
const TotalMain = styled.div`
    background: #242631;
    border-radius: 30px 30px 0px 0px;
    padding: 10px 20px;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 25vh;
`
const TotalFees = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`
const Tag = styled.div`
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
`
const Amount = styled.div`
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    opacity: 0.6;
`
const ServiceFees = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px dashed #8a8a8a48;
`
const TotalAmount = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
`
const ItemCard = ({ item, items, addItem }) => {
    const { currencyCode } = useAuth()
    return (
        <ModalWrapper className="col-sm-12 col-md-6">
            <div className="item col-12">
                <FlexRowBetween>
                    <FlexRow>
                        <img
                            src={item?.Images?.[0]?.ImageURL}
                            alt=""
                            className="img"
                        />
                        <FlexColumn className="ms-2">
                            <span>{item.ItemName}</span>
                            <div className="item-price">
                                {item.PriceBooks && item.PriceBooks.length > 0
                                    ? CommonUtility.currencyFormat(
                                          item.PriceBooks[0].OriginalPrice,
                                          currencyCode,
                                      )
                                    : null}
                            </div>
                        </FlexColumn>
                    </FlexRow>
                    <div>
                        {items[item.ItemID] && (
                            <div className="qty-selector">
                                <OuterBox>
                                    <Icon>
                                        <img
                                            src={MinusIcon}
                                            onClick={() => {
                                                addItem(item, -1)
                                            }}
                                            alt=""
                                        />
                                    </Icon>
                                    <span> {items[item.ItemID]?.Qty} </span>
                                    <Icon>
                                        <img
                                            src={AddIcon}
                                            onClick={() => {
                                                addItem(item, 1)
                                            }}
                                            alt=""
                                        />
                                    </Icon>
                                </OuterBox>
                            </div>
                        )}
                        {!items[item.ItemID] &&
                            (item.TicketQty <= 0 ? (
                                <SoldTicket>
                                    <span>Sold Out</span>
                                </SoldTicket>
                            ) : (
                                <AddItemBtn onClick={() => addItem(item, 1)}>
                                    <span>Add Item</span>
                                </AddItemBtn>
                            ))}
                    </div>
                </FlexRowBetween>
            </div>
        </ModalWrapper>
    )
}

export const QrCartScreen = () => {
    const [items, setItems] = useState({})
    const [loading, setLoading] = useState(false)
    const [openUserModal, setOpenUserModal] = useState(false)
    const navigate = useNavigate()
    const { s: storetype, id, bei } = useQueryString()
    const BusinessEventUId = bei || id
    const { data: businessData } = GetBusinessCategoryList(storetype, id)
    const { currencyCode } = useAuth()

    useEffect(() => {
        BrowserUtility.saveObj('qr-cart', {
            s: storetype,
            id,
            bei: BusinessEventUId,
        })
    }, [storetype, BusinessEventUId, id])

    useEffect(() => {
        const data = BrowserUtility.getObj('selected-items')
        setItems(data || {})
    }, [businessData])

    const totalFees = useMemo(
        () =>
            Object.values(items || {}).reduce(
                (a, b) => a + (b?.Qty ? b.Qty * b.OriginalPrice : 0),
                0,
            ),
        [items],
    )
    const serviceFees = useMemo(
        () =>
            Object.values(items || {}).reduce(
                (a, b) => a + (b?.Qty ? b.Qty * b.ServiceFee : 0),
                0,
            ),
        [items],
    )
    useEffect(() => {
        BrowserUtility.saveObj('selected-items', items)
    }, [items])

    const menuCategoryList = useMemo(
        () => businessData?.MenuItems?.Menus?.filter(ele => ele.MenuType === 3),
        [businessData],
    )

    const addItem = (item, qty) => {
        let tmp = items[item.ItemID] || {
            PerOrderLimit: item.PerOrderLimit,
            TotalLimit: item.TotalLimit,
            ProductId: item.ItemID,
            ProductItemId: item.ItemID,
            OriginalPrice:
                item.PriceBooks && item.PriceBooks.length > 0
                    ? item.PriceBooks[0].OriginalPrice
                    : 0,
            ServiceFee:
                item.PriceBooks && item.PriceBooks.length > 0
                    ? item.PriceBooks[0].ServiceFee
                    : 0,
            Qty: 0,
        }
        tmp.Qty += qty
        if (tmp.Qty === 0) {
            tmp = null
        } else if (tmp.Qty > tmp.TotalLimit) {
            tmp.Qty = tmp.TotalLimit
        }
        if (tmp && tmp.Qty > tmp.PerOrderLimit) {
            tmp.Qty = tmp.PerOrderLimit
        }
        setItems({ ...items, [item.ItemID]: tmp })
    }

    const categoryTab = useMemo(() => {
        return menuCategoryList?.[0]?.Items?.map(ele => ({
            ...ele,
            label: ele.CategoryName,
            key: ele.CategoryID,
            children: (
                <div className="">
                    {ele.Items.map(item => (
                        <div key={item.ItemID}>
                            <ItemCard
                                item={item}
                                items={items}
                                addItem={addItem}
                            />
                        </div>
                    ))}
                </div>
            ),
        }))
    }, [menuCategoryList, items])

    const clickOnNext = async (formData, skip) => {
        const data = Object.values(items || {}).filter(d => d)
        if (data.length === 0) return
        const params = data.map(item => ({
            ProductId: item.ProductId,
            ProductItemId: item.ProductItemId,
            Qty: item.Qty,
        }))
        setLoading(true)
        try {
            const result = await BusinesService.addToCart(storetype, {
                CartInfo: params,
                ButtonType: 2,
                IsDelete: false,
                ButtonId: 0,
                SendWhatsAPPTicket: formData.SendWhatsAPPTicket || false,
                IsGuestCheckout: skip || false,
                BusinessEventUId,
                UserInfo: {
                    Id: 0,
                    Email: formData.Email || '',
                    CellPhone: formData.CellPhone || '',
                    SendWhatsAPPTicket: formData.SendWhatsAPPTicket || false,
                    BusinessEventUId,
                    IsGuestCheckout: skip || false,
                },
            })
            console.log(
                '🚀 ~ file: cart.js:430 ~ clickOnNext ~ result:',
                result.Result.CheckoutToken,
            )
            BrowserUtility.save('checkout-token', result.Result.CheckoutToken)

            const { ProductBucketId } = result.Result
            BrowserUtility.saveObj(
                `product-buy-now-${ProductBucketId}`,
                result?.Result,
            )
            setLoading(false)
            navigate(
                `/qr/cart-confirmation/${ProductBucketId}?storetype=${storetype}`,
            )
        } catch (error) {
            console.log(error)
            PushNotification(
                error?.error?.ErrorMessage || NotificationText.defaultError,
                NotificationStatus.error,
            )
            setLoading(false)
        }
    }
    const onNext = () => {
        setOpenUserModal(true)
    }

    const saveUser = (formData, skip) => {
        if (formData) {
            const data = Object.values(items || {}).filter(d => d)
            if (data.length > 0) {
                clickOnNext(formData, skip)
                setOpenUserModal(false)
            } else {
                PushNotification(
                    'Please select items',
                    NotificationStatus.error,
                )
            }
        } else {
            setOpenUserModal(false)
        }
    }

    return (
        <TicketMain className="">
            <div className="d-flex justify-content-center header-bus flex-column">
                <div className="d-flex justify-content-center">
                    {businessData.BusinessFullName}
                </div>
                {!businessData?.BusinessUId && !loading && (
                    <NoEvent> No result found! </NoEvent>
                )}
                {businessData?.BusinessUId && (
                    <ModalContainer>
                        <Tabs
                            defaultActiveKey="1"
                            items={categoryTab}
                            className="menu-cart-drop"
                        />
                    </ModalContainer>
                )}
            </div>
            <TotalMain>
                <TotalFees>
                    <Tag>Total fees</Tag>
                    <Amount>
                        {CommonUtility.currencyFormat(totalFees, currencyCode)}
                    </Amount>
                </TotalFees>
                <ServiceFees>
                    <Tag>Service fees</Tag>
                    <Amount>
                        {CommonUtility.currencyFormat(
                            serviceFees,
                            currencyCode,
                        )}
                    </Amount>
                </ServiceFees>
                <TotalAmount>
                    <Tag>Total amount</Tag>
                    <Amount>
                        {CommonUtility.currencyFormat(
                            totalFees + serviceFees,
                            currencyCode,
                        )}
                    </Amount>
                </TotalAmount>
                <DangerButton
                    className="col-12 mt-2"
                    loading={loading}
                    onClick={onNext}
                >
                    Next
                </DangerButton>
            </TotalMain>{' '}
            <EmailPhoneModal open={openUserModal} onClose={saveUser} />
        </TicketMain>
    )
}

const EmailPhoneModal = ({ open, onClose }) => {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserSchema),
    })
    const SendWhatsAPPTicket = watch('SendWhatsAPPTicket')

    useEffect(() => {
        reset({ Email: '' })
    }, [open])

    const submit = async formData => {
        onClose(formData)
    }

    return (
        <PopUpModalContainer
            open={open}
            onSubmit={handleSubmit(submit)}
            onSkip={() => onClose({}, true)}
            onCancel={() => onClose(false)}
            wantSkip={true}
        >
            <Form>
                <div className="modal-title text-center my-3">
                    <h2
                        style={{
                            color: 'white',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: '32px',
                        }}
                    >
                        User Info
                    </h2>
                </div>
                <div className="mt-2 mx-4">
                    <Form.Item className="col-12">
                        <FormTextFormField
                            control={control}
                            name="Email"
                            placeholder="Email"
                            errors={errors?.Email}
                            defaultValue=""
                            style={{
                                width: '100%',
                                border: '2px solid #555',
                                borderRadius: '12px',
                                padding: '10px',
                                backgroundColor: '#333',
                                boxSizing: 'border-box',
                            }}
                        />
                    </Form.Item>
                    <Form.Item className="col-12">
                        <FormTextFormField
                            control={control}
                            name="CellPhone"
                            placeholder="Phone Number"
                            errors={errors?.CellPhone}
                            defaultValue=""
                            style={{
                                width: '100%',
                                border: '2px solid white',
                                borderRadius: '12px',
                                padding: '10px',
                                boxSizing: 'border-box',
                            }}
                        />
                    </Form.Item>
                    <div
                        className="col-12 mb-3 pointer"
                        onClick={() =>
                            setValue('SendWhatsAPPTicket', !SendWhatsAPPTicket)
                        }
                        style={{ fontWeight: 'bold' }}
                    >
                        <Checkbox
                            style={{ marginLeft: '8px' }}
                            checked={SendWhatsAPPTicket}
                            onChange={() =>
                                setValue(
                                    'SendWhatsAPPTicket',
                                    !SendWhatsAPPTicket,
                                )
                            }
                        />
                        &nbsp;&nbsp; Send payment receipt via WhatsApp
                    </div>
                    <div className="d-flex small-text">
                        <span>*</span>
                        <div className="ms-1" style={{ color: '#D3D3D3' }}>
                            Please provide a valid email and phone number to
                            receive your order confirmation.
                        </div>
                    </div>
                </div>
            </Form>
        </PopUpModalContainer>
    )
}
