import React, { useEffect, useState } from 'react'
import { Form, Table, Popconfirm } from 'antd'
import {
    DangerButton,
    FormCheckBoxField,
    FormDivider,
    FormSelectionField,
    FormTextFormField,
    PrimaryButton,
} from 'elements'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DownloadOutlined, EyeFilled } from '@ant-design/icons'
import { DangerText, FlexRow } from 'components'
import { useParams } from 'react-router-dom'
import { EventCategoryEnum, EventsService, theme } from 'utility'
import {
    GetSponserTableListByEvent,
    GetAsignSponsorTicketByEvent,
    GetSponserTableListByEventAndType,
    GetEventCategory,
    GetSponsersTicketByEvent,
    GetSeatPosition,
} from 'hooks'
import { Trash } from 'phosphor-react'
import { AssignTableListModal, ViewTableList } from './table-list'

const EventSchema = yup.object().shape({
    CompanyName: yup.string().required('*Company Name is required'),
    FullName: yup.string().required('*Contact Name is required'),
    Email: yup.string().required('*Email is required'),
    EventTicketId: yup.number().required('*Sponsor Ticket is required'),
    TotalTickets: yup
        .number()
        .required('*Ticket Quantity is required')
        .typeError('*Ticket Quantity must be a number')
        .positive('*Ticket Quantity must be greater than 0')
        .integer('*Ticket Quantity must be an integer'),
    FindMeBestSeat: yup.boolean(),
    IsAutoAssign: yup.boolean(),
    Tables: yup.array().min(0),
    IsActive: yup.boolean(),
});

const ManualScreen = () => {
    const { id: ProviderEventId } = useParams()
    const [error, setError] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [openTableModel, setOpenTableModel] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const eventId = Number(params?.id)
    const { data, setData } = GetAsignSponsorTicketByEvent(eventId)
    const { data: tableList, filterChanged } = GetSponserTableListByEvent()
    const { data: viewTableList, filterChanged: viewTableChanged } =
        GetSponserTableListByEventAndType()
    const [opewViewTable, setOpenViewTable] = useState(false)
    const { options: eventCategoryList } = GetEventCategory()
    const { options: SeatPositionList } = GetSeatPosition()
    console.log('Seat Position List:', SeatPositionList)
    const {
        optionList: ticketTypeOptionList,
        filterChanged: ticektTypeChanged,
    } = GetSponsersTicketByEvent(ProviderEventId)

    const openViewTable = event => {
        viewTableChanged({
            eventTicketId: event.EventTicketId,
        })
        setOpenViewTable(true)
    }

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        setError: setFormError,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: {
            CompanyName: '',
            FullName: '',
            Email: '',
            // Phone: '',
            Tables: [],
            EventTicketId: '',
            ShowPriceOnTicket: true,
            ShowSoldTicketInReport: true,
            TotalTickets: '',
            FindMeBestSeat: false,
            IsAutoAssign: false,
        },
    })
    const TotalTickets = watch("TotalTickets")
    const TicketType = watch("EventTicketId")
    const TicketTypeCategory = watch("TicketTypeCategory")

    useEffect(() => {
        if (TicketTypeCategory === EventCategoryEnum.CashTicket) {
            ticektTypeChanged({
                ShowAllTickets: true,
                EventId: ProviderEventId,
            })
        } else {
            ticektTypeChanged({
                ShowAllTickets: false,
                EventId: ProviderEventId,
            })
        }
    }, [TicketTypeCategory])

    useEffect(() => {
        if (tableList?.length && tableList[0].AvailableTicket < TotalTickets) {
            setFormError('TotalTickets', {
                type: 'custom',
                message: 'Available tickets limit exceed',
            })
        }
        if (tableList?.length && tableList[0].AvailableTicket > TotalTickets) {
            clearErrors('TotalTickets')
        }
    }, [tableList, TotalTickets])

    useEffect(() => {
        filterChanged({
            eventTicketId: TicketType,
        })
    }, [TicketType])

    const revoke = data => {
        try {
            EventsService.revoke(data.SponsorId)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Contact Name',
            dataIndex: 'FullName',
            key: 'FullName',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'Manual Ticket',
            dataIndex: 'EventTicketId',
            key: 'EventTicketId',
            render: text => (
                <>
                    {
                        ticketTypeOptionList.find(ele => ele.value === text)
                            ?.label
                    }
                </>
            ),
        },
        {
            title: 'Total Ticket',
            dataIndex: 'TotalTickets',
            key: 'TotalTickets',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <div>
                    <EyeFilled
                        className="me-2 pointer"
                        size={20}
                        onClick={() => openViewTable(record)}
                    />
                    {false && (
                        <DownloadOutlined
                            style={{ fontSize: '18px' }}
                            onClick={() => { }}
                        />
                    )}
                    <Popconfirm
                        title="Delete the Item"
                        description="Are you sure to revoke this tickets?"
                        onConfirm={() => revoke(record)}
                        okText="Yes"
                        cancelButtonProps={<div />}
                        cancelText="No"
                    >
                        <Trash
                            className="mx-2 pointer"
                            color={theme.colors.danger}
                            size={20}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const handleCancelEdit = () => {
        setValue('CompanyName', '')
        setValue('FullName', '')
        setValue('Email', '')
        // setValue('Phone', '')
        setValue('TotalTickets', '')
        setIsEditing(false)
        setEditIndex(null)
    }

    const submit = async formData => {
        try {
            setError('')
            setLoading(true)
            formData.EventId = eventId
            if (isEditing) {
                const updatedData = [...data]
                updatedData[editIndex] = formData
                await EventsService.addEventTicket({
                    Email: formData.Email,
                    FullName: formData.FullName,
                    TotalTickets: formData.TotalTickets,
                    CompanyName: formData.CompanyName,
                    EventId: formData.EventId,
                    EventTicketId: formData.EventTicketId,
                    SponsorId: formData.SponsorId,
                    TicketTypeCategory: formData.TicketTypeCategory,
                    ShowPriceOnTicket: formData.TicketTypeCategory === EventCategoryEnum.CashTicket ? formData.ShowPriceOnTicket : false,
                    ShowSoldTicketInReport: formData.TicketTypeCategory === EventCategoryEnum.CashTicket ? formData.ShowSoldTicketInReport : false,
                    FindMeBestSeat: formData.FindMeBestSeat,
                    IsAutoAssign: formData.IsAutoAssign,
                    SeatPosition: formData.SeatPosition,
                    Tables: formData.Tables,
                })
                setData(updatedData)
                setIsEditing(false)
                setEditIndex(null)
            } else {
                await EventsService.addEventTicket({
                    Email: formData.Email,
                    FullName: formData.FullName,
                    TotalTickets: formData.TotalTickets,
                    CompanyName: formData.CompanyName,
                    EventId: formData.EventId,
                    EventTicketId: formData.EventTicketId,
                    TicketTypeCategory: formData.TicketTypeCategory,
                    ShowPriceOnTicket: formData.TicketTypeCategory === EventCategoryEnum.CashTicket ? formData.ShowPriceOnTicket : false,
                    ShowSoldTicketInReport: formData.TicketTypeCategory === EventCategoryEnum.CashTicket ? formData.ShowSoldTicketInReport : false,
                    SeatPosition: formData.SeatPosition,
                    FindMeBestSeat: formData.FindMeBestSeat,
                    IsAutoAssign: formData.IsAutoAssign,
                    Tables: formData.Tables,
                })
                setData(prevData => [formData, ...prevData])
            }
            reset()
        } catch (error) {
            console.log('Error:', error)
        } finally {
            setLoading(false)
        }
    }
    const onTableCancel = () => {
        setOpenTableModel(false)
        setOpenViewTable(false)
    }
    const onTableSubmit = async tableData => {
        setValue('Tables', tableData)
        setOpenTableModel(false)
    }
    return (
        <div>
            <FormDivider className="mt-0" />
            {error && <DangerText>{error}</DangerText>}
            <div style={{ background: '#000000' }} className="p-4">
                <div className="row px-4 col-12">
                    <div>
                        <div className="d-flex row">
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Company Name
                                        <span className="imp">*</span>
                                    </div>
                                    <FormTextFormField
                                        control={control}
                                        name="CompanyName"
                                        placeholder="Company Name"
                                        errors={errors?.CompanyName?.message}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Full Name <span className="imp">*</span>
                                    </div>
                                    <FormTextFormField
                                        control={control}
                                        name="FullName"
                                        placeholder="FullName"
                                        errors={errors?.FullName}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Email <span className="imp">*</span>
                                    </div>
                                    <FormTextFormField
                                        control={control}
                                        name="Email"
                                        placeholder="Email"
                                        errors={errors?.Email}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="d-flex row">
                            {/* <div className="col-4">
                        <Form.Item className="col-12 col-sm-12 col-md-12">
                            <div className="label pb-1">
                                Phone <span className="imp">*</span>
                            </div>
                            <FormTextFormField
                                control={control}
                                name="Phone"
                                type="number"
                                placeholder="Phone"
                                errors={errors?.Phone?.message}
                            />
                        </Form.Item>
                    </div> */}

                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Event Category{' '}
                                        <span className="imp">*</span>
                                    </div>
                                    <FormSelectionField
                                        placeholder="Select Category"
                                        control={control}
                                        name="TicketTypeCategory"
                                        options={eventCategoryList}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Event Ticket Type{' '}
                                        <span className="imp">*</span>
                                    </div>
                                    <FormSelectionField
                                        placeholder="Select Sponsor Ticket"
                                        control={control}
                                        name="EventTicketId"
                                        options={ticketTypeOptionList}

                                    />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                {/* <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Total Tickets <span className="imp">*</span>
                                    </div>
                                    <FormTextFormField
                                        control={control}
                                        name="TotalTickets"
                                        type="number"
                                        placeholder="Total Tickets"
                                        // errors={errors?.TotalTickets?.message}
                                        rules={{
                                            required: "Total Tickets is required",
                                            min: {
                                                value: 0,
                                                message: "Total Tickets cannot be negative",
                                            },
                                        }}
                                        onInput={(e) => {
                                            e.target.value = Math.max(1, parseFloat(e.target.value) || 0);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === "") {
                                                e.target.value = 1;
                                            }
                                        }}
                                    />

                                </Form.Item> */}
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Total Tickets <span className="imp">*</span>
                                    </div>
                                    <FormTextFormField
                                        control={control}
                                        name="TotalTickets"
                                        type="number"
                                        placeholder="Ticket Quantity"
                                        errors={errors?.TotalTickets}
                                        onChange={(e) => {
                                            const value = e.target.value === '' ? '' : Math.max(1, parseFloat(e.target.value));
                                            setValue("TotalTickets", value);
                                        }}
                                        onBlur={(e) => {
                                            const value = e.target.value === '' ? 1 : e.target.value;
                                            setValue("TotalTickets", value);
                                        }}
                                    />
                                </Form.Item>

                            </div>
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Seat Position{' '}
                                        <span className="imp">*</span>
                                    </div>
                                    <FormSelectionField
                                        control={control}
                                        name="SeatPosition"
                                        placeholder="SeatPosition"
                                        errors={errors?.SeatPosition}
                                        options={SeatPositionList}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Auto-Select Best Available Seats{' '}
                                        <span className="imp">*</span>
                                    </div>
                                    <FormCheckBoxField
                                        control={control}
                                        name="FindMeBestSeat"
                                        placeholder="FindMeBestSeat"
                                        errors={errors?.FindMeBestSeat}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        IsAutoAssign{' '}
                                        <span className="imp">*</span>
                                    </div>
                                    <FormCheckBoxField
                                        control={control}
                                        name="IsAutoAssign"
                                        placeholder="IsAutoAssign"
                                        errors={errors?.IsAutoAssign}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item className="col-12 col-sm-12 col-md-12">
                                    <div className="label pb-1">
                                        Tables <span className="imp">*</span>
                                    </div>
                                    <FlexRow className="col-12 ">
                                        <DangerButton
                                            disabled={!TotalTickets}
                                            onClick={() =>
                                                setOpenTableModel(true)
                                            }
                                            className="ms-2"
                                        >
                                            Assign Tables
                                        </DangerButton>
                                    </FlexRow>
                                </Form.Item>
                            </div>
                            {TicketTypeCategory === EventCategoryEnum.CashTicket && <>
                                <div className="col-4">
                                    <Form.Item className="col-12 col-sm-12 col-md-12">
                                        <div className="label pb-1">
                                            Show Price{' '}
                                        </div>
                                        <FlexRow className="col-12 ">
                                            <FormCheckBoxField
                                                name="ShowPriceOnTicket"
                                                control={control}
                                                className="ms-2"
                                            />
                                        </FlexRow>
                                    </Form.Item>
                                </div>
                                <div className="col-4">
                                    <Form.Item className="col-12 col-sm-12 col-md-12">
                                        <div className="label pb-1">
                                            Show Sold Ticket In Report {' '}
                                        </div>
                                        <FlexRow className="col-12 ">
                                            <FormCheckBoxField
                                                name="ShowSoldTicketInReport"
                                                control={control}
                                                className="ms-2"
                                            />
                                        </FlexRow>
                                    </Form.Item>
                                </div>
                            </>}
                        </div>
                        {/* Add similar Form.Item blocks for other input fields here */}
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            {isEditing ? (
                                <div className="col-12 d-flex justify-content-center gap-2">
                                    <PrimaryButton
                                        loading={loading}
                                        className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                        onClick={handleSubmit(submit)}
                                    >
                                        Update
                                    </PrimaryButton>
                                    <PrimaryButton
                                        className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                        onClick={handleCancelEdit}
                                    >
                                        Cancel
                                    </PrimaryButton>
                                </div>
                            ) : (
                                <div className="col-12 d-flex justify-content-center gap-2">
                                    <PrimaryButton
                                        loading={loading}
                                        className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                        onClick={handleSubmit(submit)}
                                    >
                                        Add
                                    </PrimaryButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="my-3">
                        <Table
                            columns={columns}
                            dataSource={data}
                            size="small"
                        />
                    </div>
                </div>
            </div>
            {openTableModel && (
                <AssignTableListModal
                    open={openTableModel}
                    onSubmit={onTableSubmit}
                    eventId={eventId}
                    onCancel={onTableCancel}
                    maxTicket={TotalTickets}
                />
            )}
            {opewViewTable && (
                <ViewTableList
                    open={opewViewTable}
                    onCancel={onTableCancel}
                    data={viewTableList?.[0]?.Tables || []}
                />
            )}
        </div>
    )
}

export default ManualScreen
