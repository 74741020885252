import React, { useState } from 'react'
import { Form, Table } from 'antd'
import { FormTextFormField, PrimaryButton, FormDivider, FormCheckBoxField } from 'elements'
import * as yup from 'yup'
import { useParams } from 'react-router-dom';
import { DangerText, PushNotification } from 'components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditOutlined } from '@ant-design/icons'
import { EventsService, NotificationStatus, NotificationText } from 'utility'

const EventSchema = yup.object().shape({
    TicketType: yup
        .string()
        .required('*Ticket Name is required')
        .matches(/^[^\s]*$/, '*Ticket Name should not contain spaces'),
    TicketQty: yup
        .number()
        .typeError('*Ticket Quantity must be a number')
        .required('*Ticket Quantity is required')
        .positive('*Ticket Quantity must be greater than 0')
        .integer('*Ticket Quantity must be an integer'),
    IsActive: yup.boolean(),
});

const TicketFormScreen = ({ data, setData }) => {
    const { id: ProviderEventId } = useParams();
    const [error, setError] = useState('')
    const [isEditing, setIsEditing] = useState(false) // Track edit mode
    const [processing, setProcessing] = useState(false)
    const [editIndex, setEditIndex] = useState(null) // Index of the record being edited
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: {
            TicketType: '',
            TicketQty: '',
            IsActive: true,
        },
        mode: 'onBlur',
    })
    const submit = async formData => {
        try {
            setError('')
            setProcessing(true)
            if (isEditing) {
                const updatedData = [...data]
                updatedData[editIndex] = formData
                await EventsService.addSponserTicket({
                    TicketQty: formData.TicketQty,
                    TicketType: formData.TicketType,
                    ProviderEventId,
                    IsActive: formData.IsActive,
                    IsSponsorTicket: true,
                    EventTicketId: formData.EventTicketId,
                })
                setData(updatedData)
                setIsEditing(false) // Exit edit mode
                setEditIndex(null)
                reset({})
            } else {
                await EventsService.addSponserTicket({
                    TicketQty: formData.TicketQty,
                    TicketType: formData.TicketType,
                    ProviderEventId,
                    IsActive: formData.IsActive,
                    IsSponsorTicket: true,
                    TicketDescription: '',
                })
                setData(prevData => [...prevData, formData])
            }
            setProcessing(false)
            reset({})
        } catch {
            PushNotification(NotificationText.defaultError, NotificationStatus.error)
        }
    }

    const handleEdit = (record, index) => {
        reset({
            ...record,
        })
        setIsEditing(true)
        setEditIndex(index)
    }

    const handleCancelEdit = () => {
        reset({})
        setIsEditing(false)
        setEditIndex(null)
    }

    const columns = [
        {
            title: 'Ticket Type',
            dataIndex: 'TicketType',
            key: 'TicketType',
            render: text => <a>{text}</a>,
        },
        {
            title: 'TicketQty',
            dataIndex: 'TicketQty',
            key: 'TicketQty',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => (
                <div>
                    <EditOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handleEdit(record, index)}
                    />
                </div>
            ),
        },
    ]

    return (
        <div>
            <FormDivider className="mt-0" />
            {error && <DangerText>{error}</DangerText>}

            <div style={{ background: '#000000' }} className="p-4">
                <div className="row px-4 col-12">
                    <div className="col-12">
                        <Form.Item className="col-12 col-sm-12 col-md-12">
                            <div className="label pb-1">
                                Ticket Name
                                <span className="imp">*</span>
                            </div>
                            <FormTextFormField
                                control={control}
                                name="TicketType"
                                placeholder="Ticket Type"
                                errors={errors.TicketType}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\s+/g, '');
                                    setValue("TicketType", value, { shouldValidate: true });
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-12">
                        <Form.Item className="col-12 col-sm-12 col-md-12">
                            <div className="label pb-1">
                                Ticket Quantity <span className="imp">*</span>
                            </div>
                            <FormTextFormField
                                control={control}
                                name="TicketQty"
                                type="number"
                                placeholder="Ticket Quantity"
                                errors={errors?.TicketQty}
                                defaultValue=""
                                onChange={(e) => {
                                    const value = e.target.value === '' ? '' : Math.max(1, parseFloat(e.target.value));
                                    e.target.value = value;
                                    setValue("TicketQty", value);
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value === '' ? 1 : e.target.value;
                                    e.target.value = value;
                                    setValue("TicketQty", value);
                                }}
                            />
                        </Form.Item>

                    </div>
                    <div className="col-12">
                        <Form.Item className="col-12 col-sm-12 col-md-12">
                            <div className="label pb-1">
                                Is Active <span className="imp">*</span>
                            </div>
                            <FormCheckBoxField
                                control={control}
                                name="IsActive"
                                placeholder="Is Active"
                                errors={errors?.IsActive?.message}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                        {isEditing ? (
                            <div className="col-12 d-flex justify-content-center gap-2">
                                <PrimaryButton
                                    className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                    onClick={handleSubmit(submit)}
                                    loading={processing}
                                >
                                    Update
                                </PrimaryButton>
                                <PrimaryButton
                                    className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                    onClick={handleCancelEdit}
                                >
                                    Cancel
                                </PrimaryButton>
                            </div>
                        ) : (
                            <div className="col-12 d-flex justify-content-center gap-2">
                                <PrimaryButton
                                    className="col-3 p-3 d-flex flex-row align-items-center border justify-content-center"
                                    onClick={handleSubmit(submit)}
                                    loading={processing}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        )}
                    </div>

                    <div className="my-3">
                        <Table
                            columns={columns}
                            dataSource={data}
                            size="small"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketFormScreen
