export const theme = {
    colors: {
        body: '#E2E2E2',
        primary: '#0D1019',
        secondary: '#161D2A',
        danger: '#D44456',
        red: '#ff384e',
        // warning: '#ffcc00',
        warning: '#c79f00',
        white: '#ffffff',
        lightWhite: '#E8E8EA',
        grayContent: '#B9BBBF',
        soldButton: '#73777F',
        lightBlack: '#2D343F',
        box: '#161D2A',
        cyan: '#74E1CF',
        gray: '#454A55',
        black: '#393948',
        inputbackground: '#EDEFF8',
        background: '#161D2A',
        hover: '#454A55',
        success: '#628c1d',
        darkGray: '#252a33',
        imgBg: 'rgba(184, 184, 184, 0.1)',
    },
    button: {
        shadow: 'rgba(115, 205, 190, 0.25)',
        border: 'rgba(255, 255, 255, 0.18)',
        background: '#74E1CF',
        backgroundHover: '#81FAE6',
        color: '#000000',
    },
    sidebar: {
        activeText: '#FFFFFF',
        text: '#818A8E',
    },
    text: {
        primary: '#FFFFFF',
        secondary: '#E8E8EA',
        red: '#EC4C60',
        black: '#0D1019',
        grey: '#a6a6a6',
    },
}
