import { APIPath, BrowserUtility } from 'utility'
import { BaseService } from './base'

class Redeem {
    getProducts(id) {
        const TokenCode = BrowserUtility.get('checkout-token')
        return BaseService.post(
            `${APIPath.getProducts}/${TokenCode}?itemId=${id}`,
        )
    }

    updateCheckIn(params) {
        const TokenCode = BrowserUtility.get('checkout-token')

        return BaseService.post(`${APIPath.updateCheckIn}/${TokenCode}`, params)
    }
}

const RedeemService = new Redeem()
Object.freeze(RedeemService)
export { RedeemService }
