import { APIPath, CommonUtility } from 'utility'
import { BaseService } from './base'

class Business {
    get(type, id) {
        return BaseService.get(`${APIPath.GTBusiness}/${type}/${id}`)
    }

    addToCart(type, reqData) {
        return BaseService.post(
            `${APIPath.GTBusinessUser}/${type}/AddToCart/Express`,
            reqData,
            true,
            false,
            {
                AppSource: 3,
            },
        )
    }

    getPublicKey(type, id) {
        return BaseService.get(
            `${APIPath.GTBusinessUser}/${type}/GetPublicKey?productId=${id}`,
        )
    }

    buyNow(type, id) {
        return BaseService.post(
            `${APIPath.GTBusinessUser}/${type}/BuyNow?ProductBucketId=${id}`,
        )
    }

    confirmOrder(type, data) {
        const reqData = CommonUtility.objectToParams(data)
        return BaseService.get(
            `${APIPath.GTBusinessUser}/${type}/orderconfirmation/Express?${reqData}`,
        )
    }
}

const BusinesService = new Business()
Object.freeze(BusinesService)
export { BusinesService }
