/* eslint-disable react/jsx-boolean-value */
import { Modal } from 'antd'
import { OutlinedButton, PrimaryButton } from 'elements'
import React from 'react'
import styled from 'styled-components'

const ModelStyled = styled(Modal)``

export const PopUpModal = ({
    children,
    onCancel,
    onSubmit,
    width,
    wantSkip = false,
    onSkip,
    ...rest
}) => {
    return (
        <div className="model">
            <ModelStyled
                footer={[
                    wantSkip && (
                        <OutlinedButton onClick={() => onSkip()}>
                            {' '}
                            Continue as Guest{' '}
                        </OutlinedButton>
                    ),
                    <OutlinedButton onClick={() => onCancel(false)}>
                        Cancel
                    </OutlinedButton>,
                    <PrimaryButton onClick={onSubmit}> Submit </PrimaryButton>,
                ]}
                onCancel={onCancel}
                width={width || 550}
                {...rest}
            >
                {children}
            </ModelStyled>
        </div>
    )
}
